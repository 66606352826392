import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { LogService } from '../log.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  logs: any[] = [];

  constructor(private logService: LogService) {}

  ngOnInit(): void {
    this.getAuditLogs();
  }

  getAuditLogs(): void {
    this.logService.getLoginLogs().subscribe(
      (data: any) => {
        this.logs = data.value;
        console.log(this.logs);
      },
      error => {
        console.error('Error fetching audit logs', error);
      }
    );
  }
}
