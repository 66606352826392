import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface SearchResult {
  filename: string;
  score: number;
  download_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class SemanticSearchService {
  readonly baseApiUrl = '/api/svc3';

  constructor(private http: HttpClient) {}

  performSearch(query: string): Observable<SearchResult[]> {
    return this.http.post<SearchResult[]>(`${this.baseApiUrl}/recherche-semantique`, { query });
  }

  performRoleSearch(query: string): Observable<SearchResult[]> {
    return this.http.post<SearchResult[]>(`${this.baseApiUrl}/role-search`, { query });
  }

  performBoondSearch(query: string): Observable<SearchResult[]> {
    return this.http.post<SearchResult[]>(`${this.baseApiUrl}/boond-search`, { query });
  }

  performTurnoverSearch(query: string): Observable<SearchResult[]> {
    return this.http.post<SearchResult[]>(`${this.baseApiUrl}/turnover-search`, { query });
  }
}
