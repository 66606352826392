import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { EventType } from '@azure/msal-browser';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'AnalyseCv';
 
  constructor(
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {}
 
  async ngOnInit() {
    await this.authService.instance.initialize();
    const redirectResult = await this.authService.instance.handleRedirectPromise();
 
    if (redirectResult !== null && redirectResult.account !== null) {
      this.authService.instance.setActiveAccount(redirectResult.account);
      this.router.navigate(['/']);  // Redirect to home after successful login
    } else {
      const accounts = this.authService.instance.getAllAccounts();
      if (accounts.length > 0) {
        this.authService.instance.setActiveAccount(accounts[0]);
        this.router.navigate(['/']);  // Redirect to home if already logged in
      }
    }
 
    this.msalBroadcastService.inProgress$
      .pipe(filter((status: string) => status === 'none'))
      .subscribe(() => {
        const accounts = this.authService.instance.getAllAccounts();
        if (accounts.length > 0) {
          this.authService.instance.setActiveAccount(accounts[0]);
        }
      });
  }
}