<app-header></app-header>
<div class="container">
    <!-- Instructions -->
    <div class="instructions alert alert-info">
        <p><i class="fas fa-info-circle"></i> Avant de commencer, veuillez suivre ces étapes :</p>
        <ul>
            <li><i class="fas fa-upload"></i> Téléchargez votre CV au format PDF.</li>
            <li><i class="fas fa-hourglass-half"></i> Attendez quelques secondes pour télécharger le fichier avec le modèle Heptasys.</li>
        </ul>
    </div>

    <div class="form-container">
        <h2>Conversion de modèle</h2>
        <form (submit)="onSubmit()">
            <div class="form-group">
                <label for="file">Sélectionner un fichier :</label>
                <input type="file" id="file" (change)="onFileChange($event)" class="form-control" />
            </div>
            <button type="submit" class="btn-analyze"><i class="fas fa-search"></i> Télécharger</button>
        </form>
    </div>

    <!-- Loading Spinner -->
    <div *ngIf="isUploading" class="loading-spinner">
        <div class="spinner"></div>
        <div class="loading-text">Chargement...</div>
    </div>

    <!-- Response -->
    <div *ngIf="uploadResponse" class="response-container">
        <h3>Réponse</h3>
        <p>{{ uploadResponse }}</p>
    </div>
</div>
