import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
 
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private msalService: MsalService) {}
 
  isAuthenticated(): boolean {
    const account = this.msalService.instance.getAllAccounts()[0];
    return !!account;
  }
 
  getAccount(): AccountInfo | null {
    const account = this.msalService.instance.getAllAccounts()[0];
    return account || null;
  }
}