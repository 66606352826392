import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { switchMap, catchError } from 'rxjs/operators';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private graphUrl = 'https://graph.microsoft.com/v1.0/auditLogs/signIns';

  constructor(private http: HttpClient, private msalService: MsalService) {}

  getLoginLogs(): Observable<any> {
    return from(this.msalService.instance.acquireTokenSilent({
      scopes: ['AuditLog.Read.All', 'Directory.Read.All']
    })).pipe(
      switchMap(tokenResponse => {
        const headers = {
          Authorization: `Bearer ${tokenResponse.accessToken}`
        };
        return this.http.get<any>(this.graphUrl, { headers });
      }),
      catchError(error => {
        console.error('Token acquisition or API call failed', error);
        if (error instanceof InteractionRequiredAuthError) {
          return from(this.msalService.acquireTokenRedirect({
            scopes: ['AuditLog.Read.All', 'Directory.Read.All']
          }));
        } else {
          throw error;
        }
      })
    );
  }
}
