import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { AnalyseCvComponent } from './analyse-cv/analyse-cv.component'
import { LoginComponent } from './login/login.component';
import { RechrecheSemantiqueComponent } from './rechreche-semantique/rechreche-semantique.component';
import { TemplateComponent } from './template/template.component';
import { AuthGuard } from './auth.guard';
import { CallbackComponent } from './callback/callback.component';
import { AdminComponent } from './admin/admin.component';
import { AdminGuard } from './admin.guard';
 
const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'analyse-cv', component: AnalyseCvComponent, canActivate: [AuthGuard] },
  { path: 'recherche-semantique', component: RechrecheSemantiqueComponent, canActivate: [AuthGuard] },
  { path: 'template', component: TemplateComponent, canActivate: [AuthGuard] },
  { path: '.auth/login/aad/callback', component: CallbackComponent },

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
