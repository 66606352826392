<header class="app-header">
  <img src="./../../assets/images/logo_hepta.png" alt="Logo" class="logo">
  <nav class="navigation">


    <a routerLink="/" routerLinkActive="active">Home</a>
    <a routerLink="/analyse-cv" routerLinkActive="active">Analyse de CV</a>
    <a routerLink="/recherche-semantique" routerLinkActive="active">Recherche Sémantique</a>
    <a routerLink="/template" routerLinkActive="active">Template</a> <!-- New link for Template component -->
    <a routerLink="/admin" *ngIf="isAdmin">Admin Page</a>

  </nav>
</header>
