import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
 
  constructor(private authService: MsalService) {}
 
  async login() {
    await this.authService.instance.initialize();
    await this.authService.instance.handleRedirectPromise();
    this.authService.loginRedirect();
  }
}