import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
 
@Component({
  selector: 'app-callback',
  template: '<p>Redirecting...</p>'
})
export class CallbackComponent implements OnInit {
 
  constructor(private authService: MsalService, private router: Router) {}
 
  async ngOnInit() {
    const redirectResult = await this.authService.instance.handleRedirectPromise();
    if (redirectResult !== null && redirectResult.account !== null) {
      this.authService.instance.setActiveAccount(redirectResult.account);
    } else {
      const accounts = this.authService.instance.getAllAccounts();
      if (accounts.length > 0) {
        this.authService.instance.setActiveAccount(accounts[0]);
      }
    }
    this.router.navigate(['/']);  // Redirect to home after handling the redirect
  }
}
 