import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isAdmin = false;

  constructor(private msalService: MsalService) {}

  ngOnInit(): void {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && account.idTokenClaims) {
      const roles = account.idTokenClaims['roles'] || [];
      this.isAdmin = roles.includes('Admin');
    }
  }
}
