import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private apiUrl = '/api/svc2/template'; // Replace with your backend URL

  constructor(private http: HttpClient) { }

  uploadFile(file: File): Observable<Blob> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(this.apiUrl, formData, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/pdf'
      })
    });
  }
}

