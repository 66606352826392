<app-header></app-header>
<div>
  <h2>Audit Logs</h2>
  <table>
    <thead>
      <tr>
        <th>Timestamp</th>
        <th>User</th>
        <th>Action</th>
        <!-- Add more columns as needed -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let log of logs">
        <td>{{ log.createdDateTime }}</td>
        <td>{{ log.userDisplayName }}</td>
        <td>{{ log.activityDisplayName }}</td>
        <!-- Add more columns as needed -->
      </tr>
    </tbody>
  </table>
</div>
