import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private msalService: MsalService, private router: Router) {}

  canActivate(): boolean {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && account.idTokenClaims) {
      const roles = account.idTokenClaims['roles'] || [];
      if (roles.includes('Admin')) {
        return true;
      }
    }
    this.router.navigate(['/not-authorized']); // Redirect to not authorized page
    return false;
  }
}
