import { Component } from '@angular/core';
import { SemanticSearchService, SearchResult } from '../semantic-search.service';

@Component({
  selector: 'app-rechreche-semantique',
  templateUrl: './rechreche-semantique.component.html',
  styleUrls: ['./rechreche-semantique.component.css']
})
export class RechrecheSemantiqueComponent {
  query: string = '';
  searchOption: string = 'recherche-semantique';  // Default option
  results: SearchResult[] = [];
  isLoading: boolean = false;

  constructor(private searchService: SemanticSearchService) {}

  onSearch(): void {
    if (!this.query) return;

    this.isLoading = true;

    let searchObservable;

    switch (this.searchOption) {
      case 'role-search':
        searchObservable = this.searchService.performRoleSearch(this.query);
        break;
      case 'boond-search':
        searchObservable = this.searchService.performBoondSearch(this.query);
        break;
      case 'turnover-search':
        searchObservable = this.searchService.performTurnoverSearch(this.query);
        break;
      default:
        searchObservable = this.searchService.performSearch(this.query);
        break;
    }

    searchObservable.subscribe({
      next: (data) => {
        this.results = data;
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Search error:', error);
        this.isLoading = false;
      }
    });
  }
}
