<app-header></app-header> <!-- This line includes the header component -->


<!-- Hero Section -->
<section class="hero-section">
  <div class="hero-content">
    <h1>Maximisez Votre Potentiel de Carrière</h1>
    <p>Découvrez à quel point votre CV correspond à l'emploi de vos rêves dès aujourd'hui.</p>
    <button (click)="onAnalyzeClick()" class="btn btn-primary">Analyser mon CV</button>
  </div>
</section>


<!-- Features Section -->
<section class="features-section">
  <div class="feature">
    <img src="./../../assets/images/analysis.png" alt="Analyse de qualité" class="icon">
    <h3>Analyse de qualité</h3>
    <p>Obtenez des aperçus détaillés des points forts de votre CV.</p>
  </div>
  <div class="feature">
    <img src="./../../assets/images/agreement.png" alt="Accord" class="icon">
    <h3>Correspondance précise</h3>
    <p>Trouvez comment votre CV correspond aux offres d'emploi.</p>
  </div>
  <div class="feature">
    <img src="./../../assets/images/ideas.png" alt="Idées" class="icon">
    <h3>Conseils personnalisés</h3>
    <p>Recevez des conseils personnalisés pour améliorer votre CV.</p>
  </div>
  <!-- Répétez pour d'autres fonctionnalités -->
</section>


<!-- About Section -->
<section class="about-section">
  <h2>À Propos de Nous</h2>
  <p>Nous fournissons une analyse de CV de premier ordre pour vous assurer de vous démarquer sur le marché du travail.</p>
  <div class="about-details">
    <div class="about-detail">
      <h3>Innovation</h3>
      <p>Nous utilisons les dernières technologies pour analyser votre CV et fournir des insights uniques.</p>
    </div>
    <div class="about-detail">
      <h3>Personnalisation</h3>
      <p>Chaque analyse est adaptée à votre profil et aux exigences spécifiques de l'industrie.</p>
    </div>
    <div class="about-detail">
      <h3>Excellence</h3>
      <p>Notre équipe est dédiée à fournir les conseils les plus précis pour optimiser votre CV.</p>
    </div>
  </div>
</section>


<!-- Contact Section -->
<section class="contact-section">
  <h2>Contact Us</h2>
  <!-- Contact details or form -->
</section>

<!-- Footer -->
<footer class="site-footer">
  <!-- Footer content -->
</footer>
