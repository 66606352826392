<app-header></app-header>
<div class="search-container">
  <div class="instruction-card">
    <h3>Bienvenue dans la Recherche Sémantique !</h3>
    <p>Veuillez saisir une requête détaillée et valide pour trouver les résultats les plus pertinents. Utilisez des mots clés spécifiques tels que "rôle" ou "profil" pour obtenir des résultats liés aux rôles.</p>
  </div>

  <!-- Search Form -->
  <div class="search-form">
    <input [(ngModel)]="query" placeholder="Saisissez votre requête :" type="text" class="search-input">
    <div class="search-options">
      <label class="option">
        <input type="radio" name="searchOption" [(ngModel)]="searchOption" value="recherche-semantique"> General Search
      </label>
      <label class="option">
        <input type="radio" name="searchOption" [(ngModel)]="searchOption" value="role-search"> Role
      </label>
      <label class="option">
        <input type="radio" name="searchOption" [(ngModel)]="searchOption" value="boond-search"> Boond
      </label>
      <label class="option">
        <input type="radio" name="searchOption" [(ngModel)]="searchOption" value="turnover-search"> Turnover
      </label>
    </div>
    <button (click)="onSearch()" class="search-btn">Rechercher</button>
  </div>

  <!-- Loading Indicator -->
  <div *ngIf="isLoading" class="loading-indicator">
    <div class="spinner"></div>
    <p class="loading-text">Recherche en cours...</p>
  </div>

  <!-- Results Section -->
  <div *ngFor="let result of results" class="result-item">
    <a [href]="result.download_url" target="_blank" download class="result-link">{{ result.filename }}</a>
    <p class="result-score">Score : <span class="score-value">{{ result.score }}</span></p>
  </div>
</div>
