import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-analyse-cv',
  templateUrl: './analyse-cv.component.html',
  styleUrls: ['./analyse-cv.component.css']
})
export class AnalyseCvComponent {
  selectedFile: File | null = null;
  jobDescription: string = '';
  fileErrorMsg: string = '';
  analysisResult: string = '';
  sessionID: string | null = null;

  constructor(private http: HttpClient) {}

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      this.fileErrorMsg = '';
    } else {
      this.selectedFile = null;
      this.fileErrorMsg = 'No file selected';
    }
  }

  async onSubmit() {
    if (!this.selectedFile || !this.jobDescription) {
      return;
    }

    const formData = new FormData();
    formData.append('cv', this.selectedFile);
    formData.append('jobDescription', this.jobDescription);

    try {
      const response = await fetch('/api/svc1/analyse-cv', {
        method: 'POST',
        body: formData
      });

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (!reader) {
        throw new Error('Unable to read the response');
      }

      this.analysisResult = ''; // Clear previous results

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const jsonData = JSON.parse(line.slice(5));
              if (jsonData.chunk) {
                this.analysisResult += jsonData.chunk;
                // Force change detection
                this.analysisResult = this.analysisResult;
              } else if (jsonData.session_id && jsonData.status === 'complete') {
                this.sessionID = jsonData.session_id;
                console.log('Session ID received:', this.sessionID);
                setTimeout(() => this.downloadMatrix(), 5000); // Add a delay of 5 seconds
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async downloadMatrix() {
    if (!this.sessionID) {
      console.error('Session ID is not available');
      return;
    }

    try {
      const response = await this.http.get<{ sas_url: string }>(
        `/api/svc1/generate-sas-token/${this.sessionID}`, { responseType: 'json' }
      ).toPromise();
      const sasUrl = response?.sas_url;

      if (!sasUrl) {
        console.error('SAS URL not found in response');
        return;
      }

      console.log('SAS URL received:', sasUrl);

      const link = document.createElement('a');
      link.href = sasUrl;
      link.download = `competency_matrix_${this.sessionID}.docx`; // Include session ID in the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        console.error('Error fetching SAS token:', error.message, error.status, error.url);
      } else {
        console.error('Error fetching SAS token:', error);
      }
    }
  }
}
