import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
 
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router, private msalService: MsalService) {}
 
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (account && this.authService.isAuthenticated() && account.username.endsWith('@heptasys.com')) {
      return true;
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}