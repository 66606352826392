<app-header></app-header>

<!-- Main Container -->
<div class="main-container">
  <div class="header">
    <h1>Analyse de CV</h1>
  </div>

  <div class="instructions">
    <p><i class="fas fa-info-circle"></i> Veuillez suivre ces étapes :</p>
    <ol>
      <li><i class="fas fa-upload"></i> Téléchargez votre CV au format PDF ou DOCX.</li>
      <li><i class="fas fa-paste"></i> Collez la description du poste.</li>
      <li><i class="fas fa-search"></i> Cliquez sur "Analyser le CV".</li>
    </ol>
  </div>

  <form (ngSubmit)="onSubmit()" #cvForm="ngForm" novalidate class="cv-form">
    <div class="form-group">
      <label for="cvUpload">Téléchargez votre CV:</label>
      <input type="file" (change)="onFileSelected($event)" accept=".pdf,.docx" id="cvUpload" name="cvUpload" required>
      <div *ngIf="fileErrorMsg" class="error">{{ fileErrorMsg }}</div>
    </div>

    <div class="form-group">
      <label for="jobDescription">Description du poste:</label>
      <textarea id="jobDescription" rows="6" [(ngModel)]="jobDescription" name="jobDescription" #jobDesc="ngModel" required></textarea>
      <div *ngIf="jobDesc.errors?.['required'] && jobDesc.touched" class="error">
        Ce champ est obligatoire.
      </div>
    </div>

    <button type="submit" class="btn-analyze">Analyser le CV</button>
  </form>

  <div class="results-container">
    <pre>{{ analysisResult }}</pre>
  </div>
</div>
