import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-analyse-cv',
  templateUrl: './analyse-cv.component.html',
  styleUrls: ['./analyse-cv.component.css']
})
export class AnalyseCvComponent {
  selectedFile: File | null = null;
  jobDescription: string = '';
  fileErrorMsg: string = '';
  analysisResult: string = '';

  constructor(private http: HttpClient) {}

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      this.selectedFile = fileInput.files[0];
      this.fileErrorMsg = '';
    } else {
      this.selectedFile = null;
      this.fileErrorMsg = 'No file selected';
    }
  }

  async onSubmit() {
    if (!this.selectedFile || !this.jobDescription) {
      return;
    }

    const formData = new FormData();
    formData.append('cv', this.selectedFile);
    formData.append('jobDescription', this.jobDescription);

    try {
      const response = await fetch('/api/svc1/analyse-cv', {
        method: 'POST',
        body: formData
      });

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (!reader) {
        throw new Error('Unable to read the response');
      }

      this.analysisResult = ''; // Clear previous results

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const jsonData = JSON.parse(line.slice(5));
              if (jsonData.chunk) {
                this.analysisResult += jsonData.chunk;
                // Force change detection
                this.analysisResult = this.analysisResult;
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
}
